import React, { useContext, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SettingsIcon from '@mui/icons-material/Settings';
import { ShopsContext } from 'libraries/Grocery/core';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { NavLink } from 'react-router-dom';
import ToggleButton from './ToggleButton';
import classes from './SearchBox.module.css';

export default function Menu(props) {
  const { onShopsChange } = props;
  const shops = useContext(ShopsContext);
  const [active, setActiveFlag] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown'
      && (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setActiveFlag(open);
  };

  const toggleShopStatus = (shop) => {
    const newShops = [...shops];
    const selectShop = newShops.find((x) => x.username === shop.username);
    if (selectShop) selectShop.active = !selectShop.active;

    onShopsChange(newShops);
  };

  const list = () => (
    <div
      className={clsx(classes.list, false)}
      role="presentation"
      // onClick={this.toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>

        <NavLink exact to="/Settings">
          <ListItem button key="Settings">
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
        </NavLink>

        <ListItem button key="List">
          <ListItemIcon>
            <ShoppingBasketIcon />
          </ListItemIcon>
          <ListItemText primary="List" />
        </ListItem>

      </List>
      <Divider />
      <List>
        {
          shops.map((shop, index) => (
            <ToggleButton
              key={index}
              checked={shop.active}
              onChange={() => { toggleShopStatus(shop); }}
              text={shop.name}
            />
          ))

        }

      </List>
    </div>
  );

  return (

    <div>

      <IconButton className={classes.iconButton} onClick={toggleDrawer(true)} aria-label="menu">
        <MenuIcon />
      </IconButton>

      <Drawer style={{ width: '400px' }} anchor="right" open={active} onClose={toggleDrawer(false)}>

        {list('right')}

      </Drawer>
    </div>

  );
}
