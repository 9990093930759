import React, { Component } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

class SettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {

      panel1: true,
      panel2: false,
      panel3: false,
      panel4: false,

    };
  }

  handleChange(panel) { // , event
    const thisState = this.state;

    const obj = {};
    obj[panel] = !thisState[panel];
    this.setState(obj);
  }

  render() {
    const classes = {

      root: {
        width: '100%'
      },
      heading: {
        // fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0
      },
      secondaryHeading: {
        // fontSize: theme.typography.pxToRem(15),
        // color: theme.palette.text.secondary
      }
    };

    const {
      panel1, panel2, panel3, panel4
    } = this.state;

    return (

      <div className={classes.root}>
        <Accordion
          expanded={panel1}
          onChange={this.handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>About</Typography>
            <Typography className={classes.secondaryHeading}>
              ...
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Grocery application allows you to easy find groceries at different South African shops.
              Grocery can also save you money by finding similar products for less prices.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={panel2}
          onChange={this.handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography className={classes.heading}>Privacy Policy</Typography>
            <Typography className={classes.secondaryHeading}>...</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {/* <a href="" onClick="ViewPricacyPolicy();"> */}
              {/* <a href=""> Click to view our privacy policy document.</a> */}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={panel3}
          onChange={this.handleChange('panel3')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography className={classes.heading}>Developer</Typography>
            <Typography className={classes.secondaryHeading}>
              ...
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Grocery is a South African application developed by Sology group.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={panel4}
          onChange={this.handleChange('panel4')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography className={classes.heading}>Enquiries</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              For enquiries,
              {' '}
              <a
                href="mailto:msamaann@gmail.com?Subject=Grocery:Enquiry"
                target="_top"
              >
                Send an Email
              </a>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

export default SettingsPage;
