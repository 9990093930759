import React from 'react';
import { Link } from 'react-router-dom';
import classes from './ModernNavBar.module.css';

export default function ModernNavBar(props) {
  const { navItems, onChange } = props;

  const onChangeHandler = (ev, newId) => {
    onChange(newId);
  };
  const isDark = false;
  return (
    <div className={`${classes.tabNavContainer} ${isDark ? classes.dark : ''}`}>
      {
      navItems.map(
        (navItem) => <ModernNavItem navItem={navItem} onClick={onChangeHandler} key={navItem.id} />
      )
      }
    </div>
  );
}

function ModernNavItem(props) {
  const { navItem, onClick } = props;
  return (
    <Link exact="true" to={navItem.link} title={navItem.name} onClick={(ev) => { onClick(ev, navItem.id); }}>
      <div className={`${classes.tab} ${classes.teal} ${(navItem.selected) ? classes.active : ''}`}>
        {navItem.icon}
        {/* <Badge color="secondary" variant="dot"> {navItem.icon} </Badge> */}
        <p>{navItem.name}</p>
      </div>
    </Link>
  );
}
