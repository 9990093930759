import React, { useContext } from 'react';
import VerticalTabs from 'components/VerticalTabs';
import { ShopsContext, MyListsContext } from 'libraries/Grocery/core';
import ListItem from 'components/ListItem';

export default function ListsPage() {
  const shops = useContext(ShopsContext);
  const lists = useContext(MyListsContext);

  const tabsList = [];

  function getShopItems(shop) {
    return lists[0].items.filter((item) => item.shopUsername === shop.username);
  }

  shops.forEach((shop) => {
    const shopItems = getShopItems(shop);

    if (shopItems.length === 0) return;

    const section = {
      header: <img src={shop.logo} alt={shop.name} style={{ maxWidth: '50px' }} />,
      content:
  <span>
    {
        shopItems.map((item) => (
          <ListItem
            key={item.key}
            text={item.name}
            hint={item.price}
            image={item.image}
            logo={item.logo}
          />
        ))
            }
  </span>

    };

    tabsList.push(section);
  });

  return (

    <VerticalTabs tabsList={tabsList} />

  );
}
