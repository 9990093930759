import React from 'react';
import { CircularProgress, LinearProgress } from '@mui/material';
import classes from './Loader.module.css';

export default function Loader(props) {
  const { color, type } = props;
  const style = {};
  if (color) style.color = color;

  return (

    <div className={classes.loader}>
      {
        (type === '2') ? <LinearProgress color="inherit" style={style} />
          : <CircularProgress style={style} />
      }
    </div>
  );
}
