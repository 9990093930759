import React, { useState } from 'react';
import { RemoveCircle, AddCircle, DoneOutline } from '@mui/icons-material';
import { SwipeableList, SwipeableListItem } from '@sandstreamdev/react-swipeable-list';
import styles from './ListItem.module.css';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';

export default function ListItem(props) {
  const {
    swipeLeftAction, swipeRightAction, image, text, logo, hint,
  } = props;

  function buildSwipeContent(icon, contentLabel) {
    return (
      <div className={`${styles.swipeBox} ${styles.swipeToLeft}`}>
        <div className={styles.content}>
          <div className={styles.listIcon}>{icon}</div>
          <div className={styles.swipText}>{contentLabel}</div>
        </div>
      </div>
    );
  }
  const defaultLeftContent = buildSwipeContent(<AddCircle />, 'Save');

  const defaultRightContent = buildSwipeContent(<RemoveCircle />, 'Remove');

  const [leftContent, setLeftContent] = useState(defaultLeftContent);
  const [rightContent, setRightContent] = useState(defaultRightContent);

  function swipping(direction) {
    console.info(`swipe ${direction} action triggered`);

    if (direction === 'left' && swipeLeftAction) {
      swipeLeftAction();
      setLeftContent(<DoneOutline />, 'Done');
    } else if (direction === 'right' && swipeRightAction) {
      swipeRightAction();
      setRightContent(<DoneOutline />, 'Done');
    }
  }

  return (
  // SwipeableList
  // Documentation: https://github.com/sandstreamdev/react-swipeable-list
  // Example: https://sandstreamdev.github.io/react-swipeable-list/
    <div className={styles.itemContainer}>
      <SwipeableList>
        <SwipeableListItem
          swipeLeft={{
            content: leftContent,
            action: () => swipping('left'),
          }}
          swipeRight={{
            content: rightContent,
            action: () => swipping('right'),
          }}
          swipeStartThreshold={10}
        >

          <div className={styles.item}>
            <div className={styles.leftSide}>
              <img className={styles.img} src={image} alt="img" />
              <div className={styles.imageText} />
            </div>

            <div className={styles.rightSide}>
              <div className={styles.text}>
                {' '}
                {text}
                {' '}
                <img className={styles.shopLogo} src={logo} alt="img" />
              </div>
              <div>{hint}</div>
            </div>
          </div>

        </SwipeableListItem>
      </SwipeableList>
    </div>
  );
}
