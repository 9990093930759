import axios from 'axios';

export default async function request(inptUrl) {
  // jsonRequest.Access = {
  //     Name: "Grocery",
  //     Token: ".-jdka)shj$$dh#@j%!!ahdjas*dj(ka+s/??>dasdas<da&!!==||/~",
  // }
  let url = inptUrl;
  if (url.indexOf('/') !== 0) url = `/${url}`;

  // the variable name is API_PORT but react adds the prefix "REACT_APP_" for any custom var >> REACT_APP_API_PORT
  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000'; // https://grocery-sology-api.herokuapp.com
  const fullUrl = `${apiUrl}${url}`;// "https://api.sology.net";

  // let fullUrl = `/api${url}`;//"https://api.sology.net";

  const headersToSend = {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json'
  };

  const options = {
    method: 'get',
    // data: reqBody? JSON.stringify(reqBody) : null,
    // agent: httpAgent,
    headers: headersToSend,
    url: fullUrl
  };

  const result = await axios(options)
    .then(
      (res) => res.data
    )
    .catch((error) => {
    // handle error
      console.error('Error: request to', url, 'failed');
      console.error('Error Details:', error.toString());
    });

  return result;
}
