import React, { useState, useContext } from 'react';
import request from 'libraries/request';
import ListItem from 'components/ListItem';
import SearchBox from 'components/SearchBox';
import Loader from 'components/Loader';
import { ShopsContext, MyListsContext, SortItems } from 'libraries/Grocery/core';
import UserContext from 'libraries/user';
import classes from './main.module.css';

export default function HomePage(props) {
  const { onShopsChange, onMyListsChange } = props;
  const shops = useContext(ShopsContext);
  const lists = useContext(MyListsContext);
  const user = useContext(UserContext);
  console.log(user);
  const [searchResult, setSearchResult] = useState([]); // array of search results (products)
  const [searchLoading, setSearchLoading] = useState(false);
  const addToList = (item, list) => {
    const newItem = {
      key: item.key,
      name: item.name,
      price: item.price,
      image: item.image,
      logo: item.logo,
      shopUsername: item.shopUsername,
      searchTerm: item.searchTerm
    };

    const listsClone = [...lists];
    if (lists.length === 0) {
      const newList = { name: 'default', items: [] };
      newList.items.push(newItem);
      listsClone.push(newList);
      onMyListsChange(listsClone);
    } else {
      const selectList = listsClone.find((l) => (l.name === list || l.name === 'default'));

      if (selectList) {
        selectList.items.push(newItem);

        onMyListsChange(listsClone); // TBR
      }
    }

    // onMyListsChange
  };

  // eslint-disable-next-line no-async-promise-executor
  const search = (shopUsername, searchTerm) => new Promise(async (resolve, reject) => {
    const response = await request(`/search/${shopUsername}?term=${searchTerm}`).catch((err) => {
      console.error(err);
      reject(err);
    });

    if (response?.result?.length > 0) {
      const itemsList = [];
      setSearchLoading(false);
      for (let i = 0; i < response.result.length; i++) {
        const currentRecord = response.result[i];

        const item = {
          key: shopUsername + i, // searchResult.length, // i,
          // selected: false, // when the item clicked
          name: currentRecord.Name,
          image: currentRecord.Image,
          orgPrice: currentRecord.OrgPrice,
          price: currentRecord.Price,
          promoText: currentRecord.PromoText,
          textPrice: '',
          logo: currentRecord.ShopLogo,
          description: currentRecord.Description,
          barcode: currentRecord.Barcode,
          // Url: Grocery.CleanUrl(currentRecord.Url),
          shopUsername,
          weight: null,
          searchTerm, // the search term used to get this product
          // LastUpdated: Util.Time.Date() // last updated time
        };
        item.weight = SortItems.SetOrderPriority(item, searchTerm);
        itemsList.push(item);
      }
      // searchComponent.props.searchPage.showContent(searchResult);
      setSearchResult(
        (prevValue) => prevValue.concat(itemsList) // add the new item
      );
      resolve(itemsList);
    } else {
      // setFinalSearchResult(<Tip tip="We found nothing " iconClass="icon-emo-unhappy"/>);
      // console.info("Not Found");
      // //console.info("No products from " + shop.Username);
      resolve([]);
    }
  });

  const searchAll = async (searchTerm) => {
    setSearchResult([]);
    setSearchLoading(true);
    const allRequests = []; // promises
    shops.forEach((shop) => {
      if (shop.active) {
        allRequests.push(search(shop.username, searchTerm));
      }
    });
    await Promise.all(allRequests).then((data) => {
      const allData = Array.prototype.concat.apply([], data); // concatenate all

      const orderedList = allData.sort((a, b) => a.order - b.order || a.price - b.price);
      setSearchResult(orderedList);
    }).catch(() => {
      alert('request error');
    });
  };

  return (
    <div>
      <SearchBox hint="Search for coffee" onSubmit={searchAll} onShopsChange={onShopsChange} />

      <div className={classes.itemsContainer}>
        {/* <SmallList /> */}
        {(!searchLoading && (!searchResult || searchResult.length === 0)) && <div> </div>}

        {
          (searchLoading) && <Loader />
        }
        {
          (!searchLoading && searchResult) && searchResult.map((item) => (
            <ListItem
              key={item.key}
              text={item.name}
              hint={item.price}
              image={item.image}
              logo={item.logo}
              swipeLeftAction={() => addToList(item)}
            />
          ))
        }

      </div>
    </div>
  );
}
