/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import './App.css';

import {
  HomePage, ListsPage, ProfilePage, SettingsPage, ForYou
} from 'pages/core';

import {
  ModernNavBar, Login, LogoPage
} from 'components/core';

import {
  Home, LocalMall, AccountCircle, Stars
} from '@mui/icons-material';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import {
  getShops, ShopsContext, MyListsContext, getMyLists
} from 'libraries/Grocery/core';
import UserContext from 'libraries/user';

export default function App() {
  const defaultNavItems = [
    {
      id: 0, name: 'My Lists', icon: <LocalMall />, link: '/MyLists', selected: false
    },
    {
      id: 1, name: 'Home', icon: <Home />, link: '/', selected: true
    },
    {
      id: 2, name: 'For you', icon: <Stars />, link: '/ForYou', selected: false
    },
    {
      id: 3, name: 'Profile', icon: <AccountCircle />, link: '/Profile', selected: false
    },

  ];

  const [user, setUser] = useState(null);
  const [myLists, setMyLists] = useState([]);
  const [shops, setShops] = useState([]);
  const [navItems, setNavItems] = useState(defaultNavItems);
  const debuggingMode = true;

  const updateSelectedNavItem = (itemLinkOrId) => {
    const newNavItems = [...navItems];

    newNavItems.forEach((item) => {
      item.selected = false;
    });

    const isNotNumeric = Number.isNaN(parseInt(itemLinkOrId));
    if (isNotNumeric) newNavItems.find((x) => x.link === itemLinkOrId).selected = true;
    else newNavItems.find((x) => x.id === itemLinkOrId).selected = true;

    setNavItems(newNavItems);
  };

  useEffect(() => {
    async function doGetShops() {
      await getShops(setShops);
    }
    doGetShops(); // cuz use effect functions shouldnt be async

    const currentPath = window.location.pathname;
    updateSelectedNavItem(currentPath);
  }, []);// empty array to run once // you can pass components in that array to call once those components are changed

  useEffect(() => {
    getMyLists(setMyLists);
  }, []);

  return (
    <div>
      {
        (!debuggingMode && (!shops || shops.length === 0 || !user)) ? (
          <LogoPage
            Loading={(!shops || shops.length === 0)}
            content={<Login onSuccess={setUser} />}
          />
        )

          : (
            <Router>
              <div className="page">
                <Switch>

                  <UserContext.Provider value={user}>
                    <ShopsContext.Provider value={shops}>
                      <MyListsContext.Provider value={myLists}>
                        <Route exact path="/">
                          <HomePage onShopsChange={setShops} onMyListsChange={setMyLists} />
                        </Route>
                        <Route path="/Profile"><ProfilePage /></Route>
                        <Route path="/MyLists"><ListsPage /></Route>
                        <Route path="/ForYou"><ForYou /></Route>
                        <Route path="/Settings"><SettingsPage /></Route>
                      </MyListsContext.Provider>
                    </ShopsContext.Provider>
                  </UserContext.Provider>
                </Switch>
              </div>

              {/* <NavBar navItems={navItems} onChange={updateSelectedNavItem} /> */}
              <ModernNavBar navItems={navItems} onChange={updateSelectedNavItem} />
            </Router>
          )
      }
    </div>

  );
}
