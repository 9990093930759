import { createContext } from 'react';
// import request from '../request';

const MyListsContext = createContext(); // holds all shops
const defaultLists = [{ name: 'default', items: [] }];

const getMyLists = (setMyLists) => {
  setMyLists(defaultLists);
};

export { MyListsContext, getMyLists };
