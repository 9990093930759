import React, { Component } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import classes from './SearchBox.module.css';
import Menu from './Menu';

class SearchBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: ''
    };
  }

  handleOnChange = (ev) => {
    this.setState({ searchTerm: ev.target.value });
  }

  search = (ev) => {
    ev.preventDefault();
    const { searchTerm } = this.state;
    const { onSubmit } = this.props;
    onSubmit(searchTerm);
  }

  render() {
    const { hint, onShopsChange } = this.props;

    return (

      <div className={classes.searchContainer}>
        <Paper component="form" className={classes.root} onSubmit={this.search}>
          <IconButton type="submit" className={classes.iconButton} aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            autoFocus
            className={classes.input}
            placeholder={hint}
            // inputProps={{ 'aria-label': 'search google maps' }}s
            onChange={(ev) => this.handleOnChange(ev)}
            defaultValue=""
          />

          <Divider className={classes.divider} orientation="vertical" />
          <Menu onShopsChange={onShopsChange} />
        </Paper>
      </div>

    // <input style={style} onChange={ev => this.handleOnChange(ev)}
    // value={this.state.searchTerm} onKeyDown={ev => this.handleKeyDown(ev)}
    // placeholder={this.props.hint} type="text" />
    );
  }
}

export default SearchBox;
