import React, { useContext } from 'react';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import InfoIcon from '@mui/icons-material/Info';
import CallIcon from '@mui/icons-material/Call';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import UserContext from 'libraries/user';
import { clientId } from 'components/core';
import { useGoogleLogout } from 'react-google-login';
import classes from './Profile.module.css';

export default function ProfilePage() {
  const user = useContext(UserContext);
  const userName = user.profileObj.givenName;
  // const image = user.profileObj.imageUrl;

  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess: () => { window.location = '/'; },
    // jsSrc,
    // onFailure,
    // cookiePolicy,
    // loginHint,
    // hostedDomain,
    // fetchBasicProfile,
    // discoveryDocs,
    // uxMode,
    // redirectUri,
    // scope,
    // accessType,

  });
  return (
    <div className={classes.profileContainer}>
      <Box maxWidth="100%" sx={{ bgcolor: '#33adad61', height: '20vh' }} />
      <Divider className={classes.avatarDiv}>
        <Chip className={classes.Avatar} label={userName?.[0]} />
      </Divider>
      <Box className={classes.Greet}>
        {`Hi, ${userName}`}
      </Box>

      <Paper className={classes.ProfileList} sx={{ width: 320, maxWidth: '100%' }}>
        <MenuList className={classes.ProfileMenu}>
          <MenuItem component="button">
            <ListItemIcon>
              <InfoIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Information</ListItemText>

          </MenuItem>
          <Divider />
          <MenuItem component="button">
            <ListItemIcon>
              <CallIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Customer service</ListItemText>

          </MenuItem>
          <Divider />
          <MenuItem component="button">
            <ListItemIcon>
              <AppSettingsAltIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>App version</ListItemText>

          </MenuItem>
          <Divider />
          <MenuItem component="button">
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Settings</ListItemText>

          </MenuItem>
          <Divider />
          <MenuItem component="button" onClick={signOut}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Log out</ListItemText>
          </MenuItem>
        </MenuList>
      </Paper>

    </div>

  );
}
