import React, { Component } from 'react';
import '../libraries/Fontello/css/fontello.css';
import '../libraries/Fontello/css/animation.css';
import '../libraries/Fontello/css/fontello-codes.css';
import '../libraries/Fontello/css/fontello-embedded.css';
import '../libraries/Fontello/css/fontello-ie7.css';
import '../libraries/Fontello/css/fontello-ie7-codes.css';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

const toggleBtnWidth = 50;
const toggleBtnHeight = (toggleBtnWidth / 2) + 2;

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: toggleBtnWidth,
  height: toggleBtnHeight,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      // transform: 'translateX(12px)',
      transform: `translateX(${toggleBtnHeight - 4}px)`,
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: toggleBtnHeight - 4,
    height: toggleBtnHeight - 4,
    borderRadius: 10,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: toggleBtnWidth / 3,
    opacity: 1,
    // height: toggleBtnHeight - 2,
    // borderRadius: toggleBtnHeight / 2,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

class ToggleButton extends Component {
  // constructor(props) {
  //   super(props);
  //   // const { checked } = this.props;
  //   // this.state = {
  //   //   checked: checked
  //   // };
  // }

  onChangeHandler = () => {
    const { onChange } = this.props;
    // this.setState({checked: !this.props.checked});
    onChange();
  }

  render() {
    const { checked, text } = this.props;
    const style = {
      margin: '0 0 -10px 2px '
    };

    return (
      <Grid style={style} component="label" container alignItems="center" spacing={2}>

        <Grid item>
          <AntSwitch checked={checked} onChange={this.onChangeHandler} name="checkedC" />
        </Grid>
        <Grid item>{text}</Grid>
      </Grid>

    );
  }
}
export default ToggleButton;
