import { createContext } from 'react';
import request from '../request';

const ShopsContext = createContext([]); // holds all shops

function Shop(shopObject) {
  this.name = shopObject.name;
  this.logo = shopObject.logo;
  this.username = shopObject.username;
  this.website = shopObject.website;
  this.active = true;
}

async function getShops(setShops) {
  await request('/Shops/South Africa').then((data) => {
    const list = [];
    const shopsList = data.result;
    for (let i = 0; i < shopsList.length; i++) {
      const item = new Shop(shopsList[i]);
      list.push(item);
    }
    setShops?.(list);
  });
}

export { getShops, ShopsContext };
