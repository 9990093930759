const SortItems = {

  SetOrderPriority: (product, lowCaseSearchTerm) => {
    if (!product.name) {
      product.order = 16;
      return product;
    }
    lowCaseSearchTerm = lowCaseSearchTerm.trim();

    const lowCaseProduct = product.name.toLowerCase();

    // const splittedName = lowCaseProduct.split(' ');
    // const oneWordName = lowCaseProduct.replace(' ', '');// pop corn >> popcorn

    const searchNameNoSpace = lowCaseSearchTerm.replace(' ', '');
    const letterAfterTheSearchTerm = product.name[lowCaseProduct.indexOf(lowCaseSearchTerm) + lowCaseSearchTerm.length];

    const letterAfterSearchTerm = product.name[lowCaseProduct.indexOf(searchNameNoSpace) + searchNameNoSpace.length];

    if (lowCaseProduct === lowCaseSearchTerm || lowCaseProduct === searchNameNoSpace) product.order = 0; // fan
    else if (lowCaseProduct.indexOf(`${lowCaseSearchTerm} `) > -1
            || letterAfterTheSearchTerm == null
            || lowCaseProduct.indexOf(`${searchNameNoSpace} `) > -1
            || letterAfterSearchTerm == null) product.order = 1; // fan And // and Fan (nothing after it)
    else if (lowCaseProduct.indexOf(lowCaseSearchTerm) > -1
               || lowCaseProduct.indexOf(searchNameNoSpace) > -1) product.order = 2; // fantastic
    else {
      // words matching
      const matchWotrdPercentage = SortItems.CompareWords(lowCaseSearchTerm, lowCaseProduct);
      product.WordMatchPercentage = matchWotrdPercentage;

      if (matchWotrdPercentage >= 80) product.order = 3;
      else if (matchWotrdPercentage >= 60) product.order = 4;
      else if (matchWotrdPercentage >= 40) product.order = 5;
      else {
        // letters matching // needs to be more accurate
        const matchLettersPercentage = SortItems.CompareLetters(lowCaseSearchTerm, lowCaseProduct);
        product.LetterMatchPercentage = matchLettersPercentage;

        if (matchLettersPercentage >= 95) product.order = 6;
        else if (matchLettersPercentage >= 90) product.order = 7;
        else if (matchLettersPercentage >= 85) product.order = 8;
        else if (matchLettersPercentage >= 80) product.order = 9;
        else if (matchLettersPercentage >= 70) product.order = 10;
        else if (matchLettersPercentage >= 60) product.order = 11;
        else if (matchLettersPercentage >= 50) product.order = 12;
        else if (matchLettersPercentage >= 40) product.order = 13;
        else if (matchLettersPercentage >= 30) product.order = 14;
        else if (matchLettersPercentage >= 20) product.order = 15;
        else if (matchLettersPercentage >= 10) product.order = 16;
        else product.order = 17;
      }
    }

    return product.order;
  },

  // returns %
  CompareLetters: (mainWord, secondWord) => {
    const searchTermLength = mainWord.length;
    let charactersMatched = 0;
    for (let i = 0; i < searchTermLength; i++) {
      if (secondWord.indexOf(mainWord[i]) > -1) charactersMatched++;
    }

    return (charactersMatched / searchTermLength) * 100;
  },

  // returns %
  CompareWords: (mainWord, secondWord) => {
    const searchTermArray = mainWord.split(' ');
    const searchTermArrayLength = searchTermArray.length;

    let wordsMatched = 0;
    for (let i = 0; i < searchTermArrayLength; i++) {
      if (secondWord.indexOf(searchTermArray[i]) > -1) wordsMatched++;
    }

    return (wordsMatched / searchTermArrayLength) * 100;
  },

  ReOrderByBestMatch: (allMatches) => {
    console.Warn('Reordering Items');

    let itemId = 0;

    let addsCount = 0;

    const newList = [];
    allMatches.forEach((index, product) => {
      if (index !== 0 && index % 10 === 0 && addsCount <= 6) { // only show 6 adds at the top
        //   section.Add(GoogleAds.InFeed()); // every 10 rows add an ad
        addsCount++;
      }

      product.Id = itemId;
      newList.push(product);

      // row.SetImageText(product.Weight); // weight of the item 1kg, etc....
      itemId++;
    });

    if (allMatches.length === 0) {
      // const tip = GUI.Tip(`We found nothing ${GUI.Icon('icon-emo-displeased').Html}`);
      // tip.Css('margin-top', '30px');
      // section.Add(tip);
    }
  }
};

export default SortItems;
