import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { useGoogleLogin } from 'react-google-login';
import classes from './Login.module.css';
import logo from './grocery-logo.svg';
// import { refreshTokenSetup } from '../utils/refreshToken';
// https://github.com/anthonyjgrove/react-google-login
// https://www.npmjs.com/package/react-google-login

const clientId = '977211513400-ul33ossiovrco63jbvdq72jse6mlc3qh.apps.googleusercontent.com';

function Login(props) {
  const { onSuccess } = props;

  const googleOnSuccess = (response) => {
    console.log('test123');
    console.log(`mem${response}`);
    onSuccess?.(response);
    // refreshTokenSetup(res);
  };

  const { signIn, loaded } = useGoogleLogin({
    onSuccess: googleOnSuccess,
    // onFailure,
    clientId,
    isSignedIn: true,
    accessType: 'offline',
    // onAutoLoadFinished,
    // cookiePolicy,
    // loginHint,
    // hostedDomain,
    // autoLoad,
    // fetchBasicProfile,
    // redirectUri,
    // discoveryDocs,
    uxMode: 'redirect' // 'popup' || 'redirect'
    // redirectUri: '/'
    // scope,
    // responseType,
    // jsSrc,
    // onRequest,
    // prompt
  });

  return (
    <div>
      {(loaded)
        ? (
          <button onClick={signIn} className={classes.button} type="button">
            <div className={classes.icon}>
              <img src={logo} alt="Grocery" />
            </div>

            <span className={classes.buttonText}> Sign in with Google</span>
          </button>
        )
        : <div className={classes.loginLoadder}><LinearProgress color="inherit" /></div>}
    </div>
  );
}

export {
  clientId, Login
};
