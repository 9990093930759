import React from 'react';
import DownhillSkiing from '@mui/icons-material/DownhillSkiing';

export default function ForYou() {
  return (
    <h1>
      Coming soon!
      <DownhillSkiing fontSize="large" />
    </h1>
  );
}
